import { Store } from 'vuex';
import { createStore } from 'vuex-extensions';
import createPersistedState from 'vuex-persistedstate';
import { ContactsState } from '@/store/contacts';
import { SiteState } from '@/store/site';
import { StyleState } from '@/store/style';
import { AnalyticsState } from '@/store/analytics';

export interface RootState {
  site: SiteState;
  contacts: ContactsState;
  style: StyleState;
  analytics: AnalyticsState;
  contentBuilder: any;
}

// Declare empty store first, dynamically register all modules later.
export default createStore<RootState>(Store, {
  plugins: [
    createPersistedState({
      paths: ['site', 'contacts', 'style', 'analytics', 'contentBuilder'],
    }),
  ],
  strict: process.env.NODE_ENV !== 'production',
});

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $store: Store<RootState>;
  }
}
