import './registerServiceWorker';

import { createApp } from 'vue';
import App from './App.vue';
import auth from '@/plugins/Auth';
import router from './router';
import store from './store';

import '@/validator';

import { Loader } from '@/directives/Loader';
import http from '@/plugins/HTTP';
import { Logger } from '@/plugins/Logger';

createApp(App).use(http).use(store).use(router).use(auth).directive('loader', Loader).use(Logger).mount('#app');
