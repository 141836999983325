import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/',
    name: 'site',
    component: () => import(/* webpackChunkName: "site" */ '../views/Site.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/style',
    name: 'style',
    component: () => import(/* webpackChunkName: "style" */ '../views/Style.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/blocks',
    name: 'blocks',
    component: () => import(/* webpackChunkName: "blocks" */ '../views/Blocks.vue'),
    meta: {
      auth: true,
    },
  },
];
