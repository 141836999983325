
import { Options, Vue } from 'vue-class-component';
import { Icon } from '@iconify/vue';
import { useAuth } from '@websanova/vue-auth/src/v3.js';

@Options({
  name: 'DefaultLayout',
  components: { Icon },
})
export default class extends Vue {
  private auth = useAuth();

  private logout() {
    this.auth.logout();
  }

  private reset() {
    const conrirmed = confirm('Будут очищены все внесенные значения и настройки. Продоложить?');
    if (conrirmed) {
      (this.$store as any).reset();
      this.$router.push('/');
    }
  }

  private get vcsRef(): string {
    return process.env.VUE_APP_VCS_REF;
  }
}
