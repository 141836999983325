import { defineRule } from 'vee-validate';
import { required, email, integer } from '@vee-validate/rules';
import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import ru from '@vee-validate/i18n/dist/locale/ru.json';
import { setLocale } from '@vee-validate/i18n';

defineRule('required', required);
defineRule('email', email);
defineRule('integer', integer);
defineRule('phone', (value: string) => {
  if (/^\+7\s\d{3}\s\d{3}-\d{2}-\d{2}$/.test(value)) {
    return true;
  }
  return 'Нужен формат: +7 123 456-78-90';
});
defineRule('domain', (value: string) => {
  if (/^([А-ЯA-Z0-9][А-ЯA-Z0-9_-]*(?:\.[А-ЯA-Z0-9][А-ЯA-Z0-9_-]*)+):?(\d+)?\/?$/i.test(value)) {
    return true;
  }
  return 'Неверно указан домен';
});
defineRule('rootUrl', (value: string) => {
  if (/^\//.test(value)) {
    return true;
  }
  return 'URL должен начинаться с /';
});

configure({
  generateMessage: localize({
    ru,
  }),
});

setLocale('ru');
